import { Controller } from '@hotwired/stimulus'
require('swiper')
import Swiper, { Navigation, Pagination, EffectFade, Thumbs, Mousewheel }  from 'swiper';

export default class extends Controller {
  static targets = [ "gorizontalSwiper", "verticalSwiper", "swiperNext", "swiperPrev" ]

  connect() {
    this.swiper2 = new Swiper(this.gorizontalSwiperTarget, {
      loop: true,
      thumbs: {
        swiper: this.swiper1,
      },
      fadeEffect: {
        crossFade: true
      },
      modules: [Pagination, Thumbs, Mousewheel, EffectFade],
    });

    this.swiper1 = new Swiper(this.verticalSwiperTarget, {
      slidesPerView: 2,
      spaceBetween: 28,
      loop: true,
      direction: "vertical",
      thumbs: {
        swiper: this.swiper2,
      },
      breakpoints: {
        0: {
          direction: "horizontal"
        },
        768: {
          direction: "vertical"
        }
      },
      modules: [Pagination, Thumbs, Mousewheel ],
    });
  }
}