import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="map"
export default class extends Controller {
  connect() {
    let ymapsObject, ymapsPlacemarks;

    // Поиск по DOM элементов, для которых необходима инициализация Yandex Map
    const checkMapInitialize = () => {
      var mapNodes = document.querySelectorAll('[data-controller="map"]');

      if (mapNodes.length) {
        for (let i = 0; i < mapNodes.length; i++) {
          initializeYandexMap(mapNodes[i])
        }
      }
    }

    // Инициализация Yandex Map
    window.initializeYandexMap = (mapNode) => {
      var mapId = mapNode.getAttribute('id'),
          json = JSON.parse(mapNode.getAttribute('map_json'));

      if (typeof ymaps == 'undefined') {
        var script = document.createElement('script');

        script.src = `https://api-maps.yandex.ru/2.1/?lang=ru-RU&apikey=${mapNode.dataset.map}`;
        script.onload = function () {
          console.log('yandex_maps_api loaded')
          ymaps.ready(mapInit(mapId, json));
        };
        document.body.appendChild(script);
      } else {
        ymaps.ready(mapInit(mapId, json));
      }
    }

    document.addEventListener('turbo:load', function () {
      checkMapInitialize();
    });

    document.addEventListener('swiperbottom:load', function (e) {
      e.detail.swiper.on('slideChangeTransitionEnd', function() {
        let activeSlide =  this.el.querySelector('.swiper-slide-active'),
            buildingData = activeSlide.dataset,
            curPoint = ymapsPlacemarks[buildingData.buildingId];
        ymapsObject.map.setCenter([buildingData.latitude, buildingData.longitude], 12, {
          checkZoomRange: true
        });
        document.dispatchEvent(new CustomEvent('ymapsplacemark:activated', { detail: { placemark: curPoint } }))
        curPoint.options.set('iconColor', '#941503');
        curPoint.properties.set('iconCaption', buildingData.caption);
      });
    });

    // Функция инициализация карты
    var mapInit = function (mapId, json) {
      ymaps.ready(() => {
        var map = new ymaps.Map(mapId, {
          center: [58.942976, 62.8261881],
          zoom: 6,
          controls: ['zoomControl'],
          behaviors: ['default']
        }, { autoFitToViewport: 'always' })
        map.behaviors.disable('scrollZoom');

        var objectManager = new ymaps.ObjectManager();
        var points = pointCollection(json.buildings);
        var buildingsInfo = infoCollection(json.buildings);
        var geoObjects = new ymaps.GeoObjectCollection();
        ymapsObject = {map, objectManager};
        ymapsPlacemarks = [];

        for (var i = 0; i < points.length; i++) {
          let pm = new ymaps.Placemark(points[i], getPointData(buildingsInfo[i]));
          ymapsPlacemarks[i] = pm;
          geoObjects.add(ymapsPlacemarks[i]);
          document.addEventListener('ymapsplacemark:activated', function () {
            pm.options.unset('iconColor');
            pm.properties.unset('iconCaption');
          });
        }

        map.geoObjects.add(geoObjects)
        map.setBounds(map.geoObjects.getBounds());
        if (map.getZoom() > 17)
          map.setZoom(17);
      })
    }

    // Контент балуна метки
    var getPointData = function (building) {
      // var address = full_address(building)
      // var contact = contactData(building.contact)

      return {
        //iconCaption: `${building.title}`,
        // iconContent: `<b>${building.title}</b>`,
        balloonContentHeader: `<b>${building.title}</b>`,
        // balloonContentBody: `<b>${building.name}</b></br><p>Адрес: ${address}</p><p>${contact}</p>`
      };
    };

    // Сбор коллекции меток по координатам
    var pointCollection = function(buildings) {
      var collection = []

      buildings.forEach(element => {
        if (element.latitude != null && element.longitude != null) {
          collection.push([parseFloat(element.latitude), parseFloat(element.longitude)])
        }
      })

      return collection
    }

    //
    var infoCollection = function (buildings) {
      return buildings.filter(e => e.latitude != null && e.longitude != null)
    }
  }
}
