import { Controller } from '@hotwired/stimulus'
require('../../../node_modules/@fancyapps/ui/dist/fancybox/fancybox.esm.js')
import { Fancybox } from '@fancyapps/ui/dist/fancybox/fancybox.esm.js';

export default class extends Controller {
  connect() {
    this.element
    document.addEventListener('turbo:load', function () {
      Fancybox.bind("[data-fancybox]", {});
    });
  }
}
