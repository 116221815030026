import { Controller } from '@hotwired/stimulus'
require('../../../node_modules/swiper')
import Swiper, { Navigation, Pagination, Mousewheel, Autoplay, EffectFade }  from 'swiper';

export default class extends Controller {
  connect() {
    this.element
    document.addEventListener('turbo:load', function () {
      new Swiper('.mainSwiper', {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
      modules: [Autoplay, EffectFade]
      });

      new Swiper('.swiperBottom', {
        slidesPerView: 5,
        spaceBetween: 22,
        loop: true,
        centeredSlides: true,
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 30,
            centeredSlides: true,
          },
          480: {
            slidesPerView: 1
          },
          640: {
            slidesPerView: 2
          },
          1280: {
            slidesPerView: 5
          }
        },
        modules: [Navigation, Pagination],
        navigation: {
          nextEl: '.swiper-button-right',
          prevEl: '.swiper-button-left',
        },
        on: {
          init: function() {
            document.dispatchEvent(new CustomEvent('swiperbottom:load', { detail: { swiper: this } }))
          }
        }
      });
    });
  }
}