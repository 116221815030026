import { Controller } from "@hotwired/stimulus"

 
export default class extends Controller {
  connect() {
    document.querySelectorAll('a[href^="#"').forEach(link => {

      link.addEventListener('click', function(e) {
          e.preventDefault();

          let href = this.getAttribute('href').substring(1);

          const scrollTarget = document.getElementById(href);

          const topOffset = 0;
          const elementPosition = scrollTarget.getBoundingClientRect().top;
          const offsetPosition = elementPosition - topOffset;

          window.scrollBy({
              top: offsetPosition,
              behavior: 'smooth'
          });
      });
    });

    function scrollTo(to, duration = 700) {
      const
          element = document.scrollingElement || document.documentElement,
          start = element.scrollTop,
          change = to - start,
          startDate = +new Date(),
          easeInOutQuad = function (t, b, c, d) {
              t /= d / 2;
              if (t < 1) return c / 2 * t * t + b;
              t--;
              return -c / 2 * (t * (t - 2) - 1) + b;
          },
          animateScroll = function () {
              const currentDate = +new Date();
              const currentTime = currentDate - startDate;
              element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
              if (currentTime < duration) {
                  requestAnimationFrame(animateScroll);
              }
              else {
                  element.scrollTop = to;
              }
          };
      animateScroll();
    }
    
    document.addEventListener('DOMContentLoaded', function () {
        let btn = document.querySelector('#toTop');
        window.addEventListener('scroll', function () {
            if (pageYOffset > 100) {
                btn.classList.add('show');
            } else {
                btn.classList.remove('show');
            }
        });
    
        btn.onclick = function (click) {
            click.preventDefault();
            scrollTo(0, 400);
        }
    });
  }
}
