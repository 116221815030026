import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggleTitle']
  static classes = ['hidden']

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden'
  }

  toggleName() {
    this.toggleTitleTargets.forEach(toggleTitle => {
      toggleTitle.classList.toggle(this.class)
    })
  }

  hide() {
    if (this.element === event.target || this.element.contains(event.target)) return;

    this.toggleTitleTargets.forEach(toggleTitle => {
      toggleTitle.classList.add('hidden');
    })
  }
}